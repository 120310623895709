/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'EditPerPhone',
	data(){
		return{
			userInfo:'',
			userPhone:'', //手机号
			userCode:'', //验证码
			isGetCode:false,
			codeTime:0,
			sendCode:''
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			if(that.$userType == 0){
				CheckInService.GetUserPerInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userPhone = that.userInfo.Tel
					}
				})
			}else{
				CheckInService.GetUserOrgInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userPhone = that.userInfo.Tel
					}
				})
			}
		},
		GetCode(){
			var that = this
			if(that.userPhone.trim() == ''){
				$('#userPhone input').addClass('error')
				$('#userPhone .errorts').text('请输入手机号')
				return
			}else if(!(/^1[3456789]\d{9}$/.test(that.userPhone.trim()))){
				$('#userPhone input').addClass('error')
				$('#userPhone .errorts').text('请输入正确格式手机号')
				return
			}
			$('#userPhone input').removeClass('error')
			$('#userPhone .errorts').text('')
			CheckInService.SendValidCode(that.$UserID,that.userPhone.trim(),2,0).then(res=>{
				if(res.Tag == 1){
					that.sendCode = res.Description 
				}else{
					that.$alert('发送失败，请稍后重试', '提示', {
						confirmButtonText: '确定'
					});
				}
			})
			that.codeTime = 60
			that.isGetCode = true
			var setInterv = setInterval(function() {
				that.codeTime--;
				if (that.codeTime < 0) {
					that.codeTime = 60
					clearInterval(setInterv)
					that.isGetCode = false
				}
			}, 1000)
		},
		SaveEdit(){
			var that = this
			if(that.userCode.trim() == ''){
				$('#userCode input').addClass('error')
				$('#userCode .errorts').text('请输入验证码')
				return
			}else if(that.userCode.trim() != that.sendCode){
				$('#userCode input').addClass('error')
				$('#userCode .errorts').text('请输入正确验证码')
				return
			}
			$('#userCode input').removeClass('error')
			$('#userCode .errorts').text('')
			CheckInService.ValidCode(that.$UserID,that.userInfo.Tel,2,0,that.userCode.trim()).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.$router.push({
						path:'/EditSecPerPhone'
					})
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
	}
}